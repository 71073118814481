<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Preloader',
  setup (props, { expose }) {
    const visible = ref(true)
    expose({ visible })
    return { visible }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <!-- <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg> -->
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // background-color: color('blue');
  background: linear-gradient(180deg, #0A0B13 0%, #0E1423 100%);

  .spinner {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    animation: preloader-rotate 2s linear infinite;

    .path {
      animation: preloader-dash 1.5s ease-in-out infinite;
      stroke: #0094ff;
      stroke-linecap: round;
    }
  }
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes preloader-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
